<template>
  <el-dialog :title="('修改记录-'+showType)"
    :visible.sync="dialogVisible"
    custom-class="policy-dialog"
    :width="showType == '附件' ? '1160px':'86%'"
    :before-close="handleClose">
    <div class="el-collapse-box">
      <div v-if="showType == '项目备注'">
        <div class="clause-title">修改前</div>
        <el-input type="textarea"
          :rows="6"
          v-model="updateOld"
          disabled></el-input>
        <div class="clause-title">修改后</div>
        <el-input type="textarea"
          :rows="6"
          v-model="updateNew"
          disabled></el-input>
      </div>
      <div style="padding: 18px 12px 12px"
        v-if="showType == '被保险人' || showType == '投保人' || showType == '受益人'">
        <div class="clause-title">修改前</div>
        <div v-for="(item, index) in updateOld"
          :key="index">
          <div style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 24px">
            <el-radio-group disabled
              v-model="item.playersType">
              <el-radio :label="1">团体（企业、社会团体及其他组织)</el-radio>
              <el-radio :label="2">个人</el-radio>
            </el-radio-group>
          </div>
          <el-descriptions class="margin-top"
            labelClassName="policy-label-style"
            contentClassName="policy-label-style">
            <el-descriptions-item label="被保险人">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                被保险人
              </template>
              {{ item.playersName }}
            </el-descriptions-item>
            <el-descriptions-item label="证件类型">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                证件类型
              </template>
              {{ item.playersType == 1 ? '统一社会信用代码' :item.cardTypeName }}
            </el-descriptions-item>
            <el-descriptions-item label="证件类型">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                证件号码
              </template>
              {{ item.cardNo }}
            </el-descriptions-item>
            <el-descriptions-item label="出生日期："
              v-if="(item.playersType == 2)">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                出生日期
              </template>
              {{ item.birthday }}
            </el-descriptions-item>
            <el-descriptions-item label="性别"
              v-if="(item.playersType == 2)">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                性别
              </template>
              {{ item.sex }}
            </el-descriptions-item>
            <el-descriptions-item label="所在区域：">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                所在区域
              </template>
              {{ item.province }}{{ item.city }}{{ item.county }}
            </el-descriptions-item>
            <el-descriptions-item label="详细地址">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                详细地址
              </template>
              {{ item.detailAddress }}
            </el-descriptions-item>
            <el-descriptions-item label="证件有效期">
              <template slot="label">
                <!-- <span style="color:rgba(201, 66, 66, 1)">*</span> -->
                证件有效期
              </template>
              {{ item.cardStart }}-{{ item.cardStart && !item.endStart?'长期':item.endStart }}
            </el-descriptions-item>
            <el-descriptions-item label="联系人名称">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                联系人名称
              </template>
              {{ item.contactName }}
            </el-descriptions-item>
            <el-descriptions-item label="联系电话">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                联系电话
              </template>
              {{ item.contactPhone }}
            </el-descriptions-item>
            <el-descriptions-item label="联系邮箱">{{ item.contactEmail }}</el-descriptions-item>
          </el-descriptions>
        </div>
        <div class="clause-title">修改后</div>
        <div v-for="(item, index) in updateNew"
          :key="(index + 9999)">
          <div style="display: flex;align-items: center;justify-content: space-between;margin-bottom: 24px">
            <el-radio-group disabled
              v-model="item.playersType">
              <el-radio :label="1">团体（企业、社会团体及其他组织)</el-radio>
              <el-radio :label="2">个人</el-radio>
            </el-radio-group>
          </div>
          <el-descriptions class="margin-top"
            labelClassName="policy-label-style"
            contentClassName="policy-label-style">
            <el-descriptions-item label="被保险人">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                被保险人
              </template>
              {{ item.playersName }}
            </el-descriptions-item>
            <el-descriptions-item label="证件类型">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                证件类型
              </template>
              {{ item.playersType == 1 ? '统一社会信用代码' :item.cardTypeName }}
            </el-descriptions-item>
            <el-descriptions-item label="证件类型">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                证件号码
              </template>
              {{ item.cardNo }}
            </el-descriptions-item>
            <el-descriptions-item label="出生日期："
              v-if="(item.playersType == 2)">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                出生日期
              </template>
              {{ item.birthday }}
            </el-descriptions-item>
            <el-descriptions-item label="性别"
              v-if="(item.playersType == 2)">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                性别
              </template>
              {{ item.sex }}
            </el-descriptions-item>
            <el-descriptions-item label="所在区域：">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                所在区域
              </template>
              {{ item.province }}{{ item.city }}{{ item.county }}
            </el-descriptions-item>
            <el-descriptions-item label="详细地址">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                详细地址
              </template>
              {{ item.detailAddress }}
            </el-descriptions-item>
            <el-descriptions-item label="证件有效期">
              <template slot="label">
                <!-- <span style="color:rgba(201, 66, 66, 1)">*</span> -->
                证件有效期
              </template>
              {{ item.cardStart }}-{{ item.cardStart && !item.endStart?'长期':item.endStart }}
            </el-descriptions-item>
            <el-descriptions-item label="联系人名称">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                联系人名称
              </template>
              {{ item.contactName }}
            </el-descriptions-item>
            <el-descriptions-item label="联系电话">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                联系电话
              </template>
              {{ item.contactPhone }}
            </el-descriptions-item>
            <el-descriptions-item label="联系邮箱">{{ item.contactEmail }}</el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div style="padding: 18px 12px 12px"
        v-if="showType == '承保公司'">
        <div class="clause-title">修改前</div>
        <div v-for="(item, index) in updateOld"
          :key="index">
          <el-descriptions class="margin-top"
            labelClassName="policy-label-style"
            contentClassName="policy-label-style">
            <el-descriptions-item label="保险公司：">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                保险公司
              </template>
              {{ item.companyName }}
            </el-descriptions-item>
            <el-descriptions-item label="分支机构">
              <template slot="label">分支机构</template>
              {{ item.branch }}
            </el-descriptions-item>
            <el-descriptions-item label="承保比例">
              <template slot="label">承保比例</template>
              {{ item.acceptRate }}
            </el-descriptions-item>
          </el-descriptions>
          <div class="el-radio"
            style="display: block;margin-bottom: 18px">
            <span :class="{'el-radio__input': true, 'is-checked': item.acceptPrimary}">
              <span class="el-radio__inner"></span>
            </span>
            <span class="el-radio__label">主承保公司</span>
          </div>
        </div>
        <div class="clause-title">修改后</div>
        <div v-for="(item, index) in updateNew"
          :key="(index + 9999)">
          <el-descriptions class="margin-top"
            labelClassName="policy-label-style"
            contentClassName="policy-label-style">
            <el-descriptions-item label="保险公司">
              <template slot="label">
                <span style="color:rgba(201, 66, 66, 1)">*</span>
                保险公司
              </template>
              {{ item.companyName }}
            </el-descriptions-item>
            <el-descriptions-item label="分支机构">
              <template slot="label">分支机构</template>
              {{ item.branch }}
            </el-descriptions-item>
            <el-descriptions-item label="承保比例">
              <template slot="label">承保比例</template>
              {{ item.acceptRate }}
            </el-descriptions-item>
          </el-descriptions>
          <div class="el-radio"
            style="display: block;margin-bottom: 18px">
            <span :class="{'el-radio__input': true, 'is-checked': item.acceptPrimary}">
              <span class="el-radio__inner"></span>
            </span>
            <span class="el-radio__label">主承保公司</span>
          </div>
        </div>
      </div>
      <div style="padding: 18px 12px 12px"
        v-if="showType == '标的信息'">
        <!-- <div v-if="(updateOld && updateOld.length == 1)">
          <div class="clause-title">修改前</div>
          <div>
            <el-descriptions
              class="margin-top"
              labelClassName="policy-label-style"
              contentClassName="policy-label-style"
            >
              <el-descriptions-item label="设备品牌：">
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  设备品牌：
                </template>
                {{ updateOld[0].brandName }}
              </el-descriptions-item>
              <el-descriptions-item label="设备类型：">
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  设备类型：
                </template>
                {{ updateOld[0].deviceTypeName }}
              </el-descriptions-item>
              <el-descriptions-item label="设备型号：">
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  设备型号：
                </template>
                {{ updateOld[0].equipmentType }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  车架号 (机身编号) ：
                </template>
                {{ updateOld[0].frameNo }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  发动机编号：
                </template>
                {{ updateOld[0].engineNo }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">生产编号：</template>
                {{ updateOld[0].itemNo }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  新设备购置价：
                </template>
                {{ updateOld[0].purchasePrice }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  出厂日期：
                </template>
                {{ updateOld[0].productionDate }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  主险保额：
                </template>
                {{ updateOld[0].mainInsuranceAmount }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  整备质量：
                </template>
                {{ updateOld[0].curbWeight }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">年折旧率：</template>
                {{ updateOld[0].yearlyDepreciation }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">经销商：</template>
                {{ updateOld[0].agency }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">产地：</template>
                <div>
                  <div class="el-radio">
                    <span
                      :class="{'el-radio__input': true, 'is-checked': updateOld[0].origin == 1}"
                    >
                      <span class="el-radio__inner"></span>
                    </span>
                    <span class="el-radio__label">国产</span>
                  </div>
                  <div class="el-radio">
                    <span
                      :class="{'el-radio__input': true, 'is-checked': updateOld[0].origin != 1}"
                    >
                      <span class="el-radio__inner"></span>
                    </span>
                    <span class="el-radio__label">进口</span>
                  </div>
                </div>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">所在位置：</template>
                {{ updateOld[0].address }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">累计工作小时：</template>
                {{ updateOld[0].workTime }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <div class="clause-title">修改后</div>
          <div>
            <el-descriptions
              class="margin-top"
              labelClassName="policy-label-style"
              contentClassName="policy-label-style"
            >
              <el-descriptions-item label="设备品牌：">
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  设备品牌：
                </template>
                {{ updateNew[0].brandName }}
              </el-descriptions-item>
              <el-descriptions-item label="设备类型：">
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  设备类型：
                </template>
                {{ updateNew[0].deviceTypeName }}
              </el-descriptions-item>
              <el-descriptions-item label="设备型号：">
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  设备型号：
                </template>
                {{ updateNew[0].equipmentType }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  车架号 (机身编号) ：
                </template>
                {{ updateNew[0].frameNo }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  发动机编号：
                </template>
                {{ updateNew[0].engineNo }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">生产编号：</template>
                {{ updateNew[0].itemNo }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  新设备购置价：
                </template>
                {{ updateNew[0].purchasePrice }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  出厂日期：
                </template>
                {{ updateNew[0].productionDate }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  主险保额：
                </template>
                {{ updateNew[0].mainInsuranceAmount }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  <span style="color:rgba(201, 66, 66, 1)">*</span>
                  整备质量：
                </template>
                {{ updateNew[0].curbWeight }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">年折旧率：</template>
                {{ updateNew[0].yearlyDepreciation }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">经销商：</template>
                {{ updateNew[0].agency }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">产地：</template>
                <div>
                  <div class="el-radio">
                    <span
                      :class="{'el-radio__input': true, 'is-checked': updateNew[0].origin == 1}"
                    >
                      <span class="el-radio__inner"></span>
                    </span>
                    <span class="el-radio__label">国产</span>
                  </div>
                  <div class="el-radio">
                    <span
                      :class="{'el-radio__input': true, 'is-checked': updateNew[0].origin != 1}"
                    >
                      <span class="el-radio__inner"></span>
                    </span>
                    <span class="el-radio__label">进口</span>
                  </div>
                </div>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">所在位置：</template>
                {{ updateNew[0].address }}
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">累计工作小时：</template>
                {{ updateNew[0].workTime }}
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </div>  v-else-->
        <div>
          <div class="clause-title">修改前</div>
          <el-table :data="updateOld"
            border>
            <el-table-column label="序号"
              type="index"
              width="50"></el-table-column>
            <el-table-column label="设备品牌"
              prop="brand"
              width="180"></el-table-column>
            <el-table-column label="设备类型"
              prop="deviceType"
              width="180"></el-table-column>
            <el-table-column label="设备型号"
              prop="equipmentType"
              width="180"></el-table-column>
            <el-table-column label="车架号 (机身编号) "
              prop="frameNo"></el-table-column>
            <el-table-column label="发动机编号"
              prop="engineNo"></el-table-column>
            <el-table-column label="生产编号"
              prop="itemNo"></el-table-column>
            <el-table-column label="新设备购置价"
              prop="purchasePrice"></el-table-column>
            <el-table-column label="出厂日期"
              prop="productionDate"></el-table-column>
            <el-table-column label="主险保额"
              prop="mainInsuranceAmount"></el-table-column>
            <el-table-column label="整备质量"
              prop="curbWeight"></el-table-column>
            <el-table-column label="年折旧率"
              prop="yearlyDepreciation"></el-table-column>
            <el-table-column label="经销商"
              prop="agency"></el-table-column>
            <el-table-column label="产地"
              prop="origin"></el-table-column>
            <el-table-column label="所在位置"
              prop="address"></el-table-column>
            <el-table-column label="累计工作小时"
              prop="workTime"></el-table-column>
          </el-table>
          <div class="clause-title">修改后</div>
          <el-table :data="updateNew"
            border>
            <el-table-column label="序号"
              type="index"
              width="50"></el-table-column>
            <el-table-column label="设备品牌"
              prop="brand"
              width="180"></el-table-column>
            <el-table-column label="设备类型"
              prop="deviceType"
              width="180"></el-table-column>
            <el-table-column label="设备型号"
              prop="equipmentType"
              width="180"></el-table-column>
            <el-table-column label="车架号 (机身编号) "
              prop="frameNo"></el-table-column>
            <el-table-column label="发动机编号"
              prop="engineNo"></el-table-column>
            <el-table-column label="生产编号"
              prop="itemNo"></el-table-column>
            <el-table-column label="新设备购置价"
              prop="purchasePrice"></el-table-column>
            <el-table-column label="出厂日期"
              prop="productionDate"></el-table-column>
            <el-table-column label="主险保额"
              prop="mainInsuranceAmount"></el-table-column>
            <el-table-column label="整备质量"
              prop="curbWeight"></el-table-column>
            <el-table-column label="年折旧率"
              prop="yearlyDepreciation"></el-table-column>
            <el-table-column label="经销商"
              prop="agency"></el-table-column>
            <el-table-column label="产地"
              prop="origin"></el-table-column>
            <el-table-column label="所在位置"
              prop="address"></el-table-column>
            <el-table-column label="累计工作小时"
              prop="workTime"></el-table-column>
          </el-table>
        </div>
      </div>
      <div style="padding: 18px 12px 12px"
        v-if="showType == '保障计划'">
        <div class="clause-title">修改前</div>
        <el-table :data="updateOld"
          border
          style="width: 1000px">
          <el-table-column label="序号"
            type="index"
            width="50"></el-table-column>
          <el-table-column prop="insuranceTypeName">
            <template slot="header">
              <div>险种（条款）名称</div>
            </template>
          </el-table-column>
          <el-table-column prop="sumInsured"
            label="保额/限额">
            <template slot="header">
              <div>保额/限额</div>
            </template>
          </el-table-column>
          <el-table-column prop="limitCompensation"
            label="每次赔偿限额"></el-table-column>
          <el-table-column prop="rate"
            label="年费率">
            <template slot-scope="scope">{{ scope.row.rate }}</template>
          </el-table-column>
          <el-table-column prop="premium"
            label="保费">
            <template slot="header">
              <!-- <span class="nmmdx">*</span> -->
              <div>保费(元)</div>
            </template>
          </el-table-column>
        </el-table>
        <div class="clause-title">修改后</div>
        <el-table :data="updateNew"
          border
          style="width: 1000px">
          <el-table-column label="序号"
            type="index"
            width="50"></el-table-column>
          <el-table-column prop="insuranceTypeName">
            <template slot="header">
              <div>险种（条款）名称</div>
            </template>
          </el-table-column>
          <el-table-column prop="sumInsured"
            label="保额/限额">
            <template slot="header">
              <div>保额/限额</div>
            </template>
          </el-table-column>
          <el-table-column prop="limitCompensation"
            label="每次赔偿限额"></el-table-column>
          <el-table-column prop="rate"
            label="年费率">
            <template slot-scope="scope">{{ scope.row.rate }}</template>
          </el-table-column>
          <el-table-column prop="premium"
            label="保费">
            <template slot="header">
              <!-- <span class="nmmdx">*</span> -->
              <div>保费(元)</div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="showType == '免费扩展条款' || showType == '特别约定' || showType == '未扩展不计免赔' || showType == '扩展不计免赔2' || showType == '扩展不计免赔1'">
        <div class="clause-title">修改前</div>
        <el-input type="textarea"
          v-model="updateOld"
          disabled
          :rows="6"></el-input>
        <div class="clause-title">修改后</div>
        <el-input type="textarea"
          v-model="updateNew"
          disabled
          :rows="6"></el-input>
        <!-- <div class="clause-title">每次事故绝对免赔额</div>
        <div>
          <div class="policy-label-style">
            扩展不计免赔1（0免赔）
          </div>
          <el-input
            type="textarea"
            style="margin-bottom: 12px;"
            v-model="detailData.absoluteDeductible"
            :rows="6"
            placeholder="请输入内容"
          ></el-input>
        </div>
        <div
          v-if="(detailData.absoluteDeductible2Checkbox && permissions.absoluteDeductible2)"
        >
          <div class="policy-label-style">
            扩展不计免赔2（绝对免赔1000元）
          </div>
          <el-input
            type="textarea"
            style="margin-bottom: 12px;"
            v-model="detailData.absoluteDeductible2"
            :rows="6"
            placeholder="请输入内容"
          ></el-input>
        </div>
        <div
          v-if="(detailData.absoluteDeductible3Checkbox && permissions.absoluteDeductible3)"
        >
          <div class="policy-label-style">
            未扩展不计免赔
          </div>
          <el-input
            type="textarea"
            v-model="detailData.absoluteDeductible3"
            :rows="6"
            placeholder="请输入内容"
          ></el-input>
        </div>-->
      </div>
      <div v-if="showType == '附件'">
        <div class="clause-title">修改前</div>
        <div class="drag">
          <!-- 上方待拖入文件区 -->
          <div v-for="(ditem, dindex) in OldAttachments"
            :key="dindex"
            class="drag-main">
            <!-- 上方选择框和提示文字 -->
            <div class="drag-area-top">
              <div class="top-left">
                <span>{{ ditem.leftTitle }}</span>
                <el-select v-model="ditem.selectValue"
                  disabled
                  placeholder="请选择">
                  <el-option v-for="sel in ditem.selects"
                    :key="sel.value"
                    :label="sel.label"
                    :value="sel.value"></el-option>
                </el-select>
              </div>
              <span class="top-right">
                <span v-if="ditem.tipsRequire">*</span>
                {{ ditem.rightTips }}
              </span>
            </div>
            <!-- 文件拖入区 -->
            <div class="drag-in-area">
              <div>
                <transition-group v-if="ditem.urls && ditem.urls.length"
                  class="transition-group">
                  <FileIcon v-for="(file, index) in ditem.urls"
                    :key="file.id"
                    :suffix="file.suffix"
                    :fileName="file.name"
                    @click.native="preView(ditem.urls, index)"></FileIcon>
                </transition-group>
                <div v-else
                  class="transition-group transition-group-none">
                  <span key="none-text">请把下方对应文件拖拽到此</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="clause-title">修改后</div>
        <div class="drag">
          <!-- 上方待拖入文件区 -->
          <div v-for="(ditem, dindex) in NewAttachments"
            :key="(dindex + 9999)"
            class="drag-main">
            <!-- 上方选择框和提示文字 -->
            <div class="drag-area-top">
              <div class="top-left">
                <span>{{ ditem.leftTitle }}</span>
                <el-select v-model="ditem.selectValue"
                  disabled
                  placeholder="请选择">
                  <el-option v-for="sel in ditem.selects"
                    :key="sel.value"
                    :label="sel.label"
                    :value="sel.value"></el-option>
                </el-select>
              </div>
              <span class="top-right">
                <span v-if="ditem.tipsRequire">*</span>
                {{ ditem.rightTips }}
              </span>
            </div>
            <!-- 文件拖入区 -->
            <div class="drag-in-area">
              <div>
                <transition-group v-if="ditem.urls && ditem.urls.length"
                  class="transition-group">
                  <FileIcon v-for="(file, index) in ditem.urls"
                    :key="file.id"
                    :suffix="file.suffix"
                    :fileName="file.name"
                    @click.native="preView(ditem.urls, index)"></FileIcon>
                </transition-group>
                <div v-else
                  class="transition-group transition-group-none">
                  <span key="none-text">请把下方对应文件拖拽到此</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import draggable from "vuedraggable";
import FileIcon from "@/components/DragMultipleUpload/FileIcon.vue";
export default {
  components: { draggable, FileIcon },
  props: {
    showDialog: Boolean,
    showType: {
      type: String,
      default: ''
    },
    updateOld: {
      type: [Object, Array, String],
      default: () => []
    },
    updateNew: {
      type: [Object, Array, String],
      default: () => []
    },
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        } else {
          this.$emit('change', true);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          if (this.showType == '附件') {
            this.OldAttachments = this.handleAttachments(this.updateOld);
            this.NewAttachments = this.handleAttachments(this.updateNew);
          }
          this.dialogVisible = true;
        } else {
          this.dialogVisible = false;
        }
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      OldAttachments: [],
      NewAttachments: [],
      locationOptions: [
        {
          type: "dqzl", //类型名字（返回）
          selects: [
            { label: "身份证", value: 1 },
            { label: "护照", value: 2 },
            { label: "军人证", value: 3 },
            { label: "工商营业执照", value: 4 },
            { label: "统一社会信用证", value: 5 },
          ],
          leftTitle: "倒签资料",
          rightTips: "出单必录",
          tipsRequire: true,
          isOther: false,
        },
        // {
        //   type: "tbzlqr",
        //   selects: [
        //     { label: "签章投保单", value: 1 },
        //     { label: "电子签章投保单", value: 2 },
        //   ],
        //   leftTitle: "投保确认资料",
        //   rightTips: "出单必录",
        //   tipsRequire: true,
        //   isOther: false,
        // },
        {
          type: "qcxzl",
          selects: [
            { label: "投保清单", value: 1 },
          ],
          leftTitle: "企财险资料",
          rightTips: "出单必录",
          tipsRequire: true,
          isOther: false,
        },
      ],
      otherOption: {
        selects: [
          { label: "倒签资料》身份证", value: 1 },
          { label: "财企险资料》投保单资料清单", value: 2 },
          { label: "其他", value: 3 },
        ],
        leftTitle: "请选择资料",
        isOther: true,
      }
    };
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
    },
    handleAttachments(e) {
      let attachments = JSON.parse(JSON.stringify(this.locationOptions));
      if (e) {

        let idInd = 1;
        for (const key in e) {
          let type = (key + '').split('-');
          if (type[0] == "dqzl") {
            attachments[0] = {
              ...attachments[0],
              type: 'dqzl',
              selectValue: Number(type[1]),
              urls: e[key].map(val => {
                idInd++;
                return {
                  suffix: val.fileType,
                  name: val.fileName,
                  url: val.url,
                  id: idInd
                };
              })
            };
          } else if (type[0] == 'tbzlqr') {
            attachments[1] = {
              ...attachments[1],
              type: 'tbzlqr',
              selectValue: Number(type[1]),
              urls: e[key].map(val => {
                idInd++;
                return {
                  suffix: val.fileType,
                  name: val.fileName,
                  url: val.url,
                  id: idInd
                };
              })
            };
          } else {
            attachments.push({
              ...this.otherOption,
              type: type[0] == 'qt' ? undefined : type[0],
              selectValue: Number(type[1]),
              urls: e[key].map(val => {
                idInd++;
                return {
                  suffix: val.fileType,
                  name: val.fileName,
                  url: val.url,
                  id: idInd
                };
              })
            });
          }
        }
      }
      return attachments;
    }
  }
}
</script>

<style lang="scss" scoped>
.el-collapse-box {
  height: 100%;
  .view-title {
    flex: 1;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      height: 48px;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      img {
        width: 6px;
        height: 24px;
        margin-right: 8px;
      }
    }
    &-right {
      display: flex;
      align-items: center;
    }
    &-float {
      position: fixed;
      background-color: #ffffff;
      border-bottom: none;
      border-radius: 8px 8px 0 0;
      padding: 12px 24px 0;
      margin: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.12);
      z-index: 3;
    }
  }
  .el-collapse {
    border: none;
    .el-collapse-item {
      margin-bottom: 10px;
      padding: 24px;
      border-radius: 8px;
      border: none;
      background-color: #fff;
      overflow: hidden;
      ::v-deep .el-collapse-item__arrow {
        display: none;
      }
    }
  }
  .insurance-company {
    display: flex;
    justify-content: flex-end;
    max-width: 1000px;
    font-weight: bold;
    color: #4278c9;
    font-size: 16px;
    margin-top: 16px;
  }
  .clause-title {
    margin: 16px 0;
    font-size: 16px;
    font-weight: bold;
    color: #333333;
  }
  ::v-deep .el-descriptions-item__cell {
    padding-bottom: 20px;
  }
  .steps-title {
    display: flex;
    align-items: center;
    margin-top: 30px;
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    .decoration {
      height: 20px;
      width: 6px;
      border-radius: 3px;
      background-color: #4278c9;
      margin-right: 10px;
      margin-left: 14px;
    }
    .decoration2 {
      flex: 1;
      height: 1px;
      margin-left: 8px;
      margin-right: 20px;
      background-color: #cccccc;
    }
  }
  .steps-box {
    height: calc(100% - 50px);
    padding-top: 24px;
    overflow: auto;
    .step {
      display: flex;
      &-left {
        position: relative;
        width: 6px;
        background-color: #e1e1e1;
        margin-right: 10px;
        margin-left: 14px;
        img {
          position: absolute;
          top: -4px;
          left: 50%;
          transform: translateX(-50%);
          width: 16px;
          height: 16px;
        }
      }
      &-left2 {
        background-color: transparent;
      }
      &-right {
        flex: 1;
        .time {
          font-size: 12px;
          font-weight: 300;
          color: #333333;
          margin-bottom: 4px;
        }
        .step-body {
          font-size: 12px;
          color: #333333;
        }
      }
    }
  }
  .drag {
    display: flex;
    flex-wrap: wrap;
    height: 350px;
    overflow-y: auto;
  }
  .bottom-btn {
    margin: 0 28px;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    color: #ffffff;
  }
  .transition-group {
    min-height: 234px;
    width: 100%;
    display: inline-block;
  }
  .transition-group-none {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #cccccc;
  }
  .drag-main {
    width: 512px;
    margin-left: 28px;
    margin-top: 24px;
    .drag-area-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .top-left {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        > span {
          margin-right: 12px;
        }
      }
      .top-right {
        font-size: 12px;
        font-weight: 300;
        color: #666666;
        > span {
          color: #c94242ff;
        }
      }
    }
    .drag-in-area {
      overflow: auto;
      background: #f3f8ff;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      border: 1px dashed #d1e3fe;
      height: 240px;
      margin-top: 12px;
    }
  }
}
</style>
<style lang="scss">
.policy-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
  &-b {
    height: auto;
    padding: 10px 24px;
    &-img {
      display: block;
      width: 40px;
      height: 40px;
      margin: 14px auto 12px;
    }
    &-text {
      text-align: center;
      font-size: 18px;
      margin-bottom: 5px;
      color: #333333;
    }
    .upload-demo {
      width: 100%;
      .el-upload {
        width: 100%;
      }
      .el-upload-dragger {
        width: 100%;
        height: auto;
        border: none;
        background-color: transparent;
      }
    }
  }
}
.policy-label-style {
  font-size: 14px;
  color: #333333;
}
.el-collapse-box {
  .el-table__row {
    .cell {
      color: #333;
      font-size: 14px;
    }
  }
  .el-table__header-wrapper {
    background-color: #f0f4fb;
    // overflow: hidden;
  }
  .has-gutter,
  .el-table__fixed-header-wrapper {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
    background: #f0f4fb;
    tr {
      background-color: #f0f4fb;
      border-radius: 8px;
      overflow: hidden;
    }
    th {
      background-color: transparent;
      font-size: 14px;
      font-weight: bold;
      color: #333333;
    }
  }
  .el-table__body {
    tr {
      position: relative;
    }
    // .table-del {
    //   position: absolute;
    //   top: 50%;
    //   right: -50px;
    //   transform: translateY(-50%);
    //   font-size: 30px;
    //   color: #c94242;
    //   cursor: pointer;
    // }
    .el-form-item {
      margin: 0;
    }
  }
  .plan-table {
    .has-gutter,
    .el-table__fixed-header-wrapper {
      tr {
        overflow: hidden;
      }
    }
    .el-table__body-wrapper {
      overflow: visible;
    }
  }
  .ep-b {
    font-size: 14px;
    color: #0080ff;
    margin-left: 24px;
    cursor: pointer;
    border-bottom: 1px solid #0080ff;
  }
  .policy-ep-img {
    width: 20px;
    height: 20px;
    margin-right: 14px;
  }
}
</style>