<template>
<div>
  <div class="audit-process">
    <div class="detail">
      <div class="record"
        v-for="item,index in auditList"
        :key="index">
        <div class="line"
          v-if="index < auditList.length -1"></div>
        <div class="avatar">
          {{ item.nodeExamines && item.nodeExamines.length ? item.nodeExamines[item.nodeExamines.length-1].nodeApproverName.substring(0,1) : '' }}
          <i class="el-icon-check status status-success"
            v-if="item.nodeExamines && item.nodeExamines.length && item.nodeExamines[item.nodeExamines.length-1].nodeStatus == 0"></i>
          <i class="el-icon-check status status-success"
            v-if="item.nodeExamines && item.nodeExamines.length && item.nodeExamines[item.nodeExamines.length-1].nodeStatus == 3"></i>
          <i class="el-icon-close status status-error"
            size="18"
            v-if="item.nodeExamines && item.nodeExamines.length && item.nodeExamines[item.nodeExamines.length-1].nodeStatus == 1"></i>
          <i class="el-icon-more status status-wait"
            v-if="item.nodeExamines && item.nodeExamines.length && item.nodeExamines[item.nodeExamines.length-1].nodeStatus == 2"></i>
          <img src="@/assets/font/return-返回左.png"
            v-if="item.nodeExamines && item.nodeExamines.length && item.nodeExamines[item.nodeExamines.length-1].nodeStatus == 4"
            class="status status-return"
            style="color:#fff"
            alt="">
        </div>
        <div class="info">
          <div class="name">{{ item.nodeName }}</div>
          <div class="state" v-for="(item2, index2) in item.nodeExamines" :key="index2">
            <span style="margin-right:8px">{{ item2.nodeApproverName }}</span>
            <span v-if="item2.nodeStatus" :class="{ 'text-success': item2.nodeStatus == 3,'text-error': item2.nodeStatus == 1,'text-return': item2.nodeStatus == 4,'text-wait': item2.nodeStatus == 2, }">{{ item2.nodeStatusDesc }}</span>
            <span class="time"
              v-if="item2.nodeApproveTime">{{ item2.nodeApproveTime }}</span>
          </div>
          <div class="comment" v-if="item.nodeExamines && item.nodeExamines.length && item.nodeExamines.some(val=> val.nodeOpinion)">
            <div v-for="(item2, index2) in item.nodeExamines" :key="index2" style="border-bottom: 1px dashed #DEDEDE;margin-bottom:10px;padding-bottom:10px;">{{ item2.nodeOpinion }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    auditList: {
      type: Array,
      default: () => []
    },
    negotiationList: {
      type: Array,
      default: () => []
    },
    closeReason: {
      type: String,
      default: ''
    },
    isTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
    };
  },
  created() {
  },
  computed: {
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
.negotiation {
  padding: 20px 10px;
  .title {
    color: #4278c9;
    margin-bottom: 10px;
    font-weight: bold;
    margin-bottom: 12px;
  }
}
.audit-process {
  padding: 20px 30px;
  background-color: #f7fafd;
  border-radius: 10px;
  .title {
    color: #4278c9;
    margin-bottom: 10px;
    font-weight: bold;
  }
  .detail {
    .record {
      position: relative;
      display: flex;
      min-height: 140px;
      // margin-bottom: 90px;
      padding-bottom: 20px;
      &:last-child {
        margin-bottom: 0px;
      }
      .avatar {
        width: 56px;
        height: 56px;
        background: #d9e5f4;
        border: 1px solid #4278c9;
        border-radius: 50%;
        margin-right: 15px;
        position: relative;
        font-size: 24px;
        font-weight: bold;
        color: #4278c9;
        text-align: center;
        line-height: 56px;
        .status {
          position: absolute;
          left: 80%;
          transform: translateX(-50%);
          height: 15px;
          width: 15px;
          bottom: -3px;
          font-size: 12px;
          line-height: 15px;
          border-radius: 50%;
          font-weight: bold;
          color: #fff;
          border: 2px solid #fff;
        }
        .status-success {
          background: #00bc0d;
        }
        .status-error {
          background: #c94242;
        }
        .status-wait {
          background: #0080ff;
          font-size: 8px;
        }
        .status-return {
          background: #b1b1b1;
        }
        .status-keep {
          background: #0080ffff;
          font-size: 14px;
        }
      }
      .info {
        position: relative;
        flex: 1;
        .name {
          font-weight: bold;
          min-height: 16px;
        }
        .state {
          margin: 5px 0;
          .time {
            margin-left: 5px;
          }
          .text-success {
            color: #00bc0d;
            font-weight: bold;
          }
          .text-error {
            color: #c94242;
            font-weight: bold;
          }
          .text-return {
            color: #b1b1b1;
            font-weight: bold;
          }
          .text-wait {
            color: #0080ff;
            font-weight: bold;
          }
        }
        .comment {
          // position: absolute;
          // top: 45px;
          max-width: 400px;
          padding: 10px 15px;
          border-radius: 10px;
          background-color: #e9eff9;
          line-height: 20px;
        }
      }
      .line {
        position: absolute;
        left: 28px;
        transform: translateX(-50%);
        height: calc(100% - 66px);
        width: 6px;
        top: 64px;
        border-radius: 3px;
        background-color: #ccc;
      }
    }
  }
}
</style>
